.thumbnails-options {
    width: 100%;
}

.customize-thumbnails-cont {
    width: 100%;
}

.customized-thumbnails-title {
    display: flex;
    align-items: center;
    height: 52px;
    gap: 7px;
}

.upload-thumbnail-img button {
    margin-left: 25px;
}

.upload-thumbnail-img {
    position: relative;
    width: fit-content;
    margin: 0 3px;
    margin-bottom: 10px;
    margin-left: 25px;
}

.upload-thumbnail-img img {
    height: 95px;
}

.thumbnail-overlay-icon {
    position: absolute;
    top: 0;
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: space-between;
    background-color: rgba(100, 100, 100, 0.5);
    visibility: hidden;
}

.thumbnail-redo-max-icon {
    font-size: 20px;
    margin: 5px;
    cursor: pointer;
}

.upload-video-thumbnail-cont button {
    width: fit-content;
    height: 25px;
    padding: 3px 10px;
    font-size: 15px;
}

.preview-thumbnail {
    position: relative;
    width: fit-content;
    height: fit-content;
}

.preview-thumbnail img {
    width: 720px;
}

.close-preview-thumbnail {
    position: absolute;
    top: 0;
    right: 0;
    display: flex;
    align-items: center;
    gap: 3px;
    padding: 5px;
    width: fit-content;
    font-size: 15px;
    cursor: pointer;
}

/* HOVER */
.upload-thumbnail-img:hover > .thumbnail-overlay-icon {
    visibility: visible;
}

.thumbnail-redo-max-icon:hover,
.close-preview-thumbnail:hover {
    color: var(--dark-yellow);
}

@media screen and (max-width: 768px) {
    .customized-thumbnails-title {
        height: auto;
    }
    .customized-thumbnails-title .check-box-icon {
        margin-right: 9px;
    }
    .upload-thumbnail-img {
        margin-top: 12px;
        margin-bottom: 3px;
        margin-left: 32px;
    }
}
