.timeline-event-options-cont {
    display: flex;
    width: 100%;
}

.timeline-events-cont {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    gap: 7px 15px;
}

.timeline-event-option {
    display: flex;
    align-items: center;
    cursor: pointer;
    gap: 2px;
    font-size: 14px;
}

.timeline-event-option.active {
    color: var(--dark-yellow);
}

.timeline-event-option svg {
    margin-top: 1px;
}

.timeline-event-options-title {
    font-size: 14px;
    min-width: 70px;
    margin-top: -1px;
}

.match-timeline-cont {
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 10px;
    margin-bottom: 20px;
}

.match-timeline-logo {
    height: 30px;
}

.timeline-team-separator {
    width: 100%;
    border-bottom: 1px solid var(--dark-yellow);
}

.match-timeline {
    position: relative;
    display: flex;
    width: 100%;
    gap: 15px;
    height: 150px;
}

.timeline-separator {
    position: absolute;
    top: 50%;
    width: 100%;
    height: 5px;
    z-index: 10;
    cursor: pointer;
    background-color: var(--light-blue);
}

.timeline-progress {
    pointer-events: none;
    height: 100%;
    background-color: var(--dark-yellow);
}

.timeline-progress-dot {
    pointer-events: none;
    position: absolute;
    top: 50%;
    height: 10px;
    width: 10px;
    background-color: white;
    border-radius: 50%;
    transform: translate(-50%, -50%);
}

.timeline-game-icon {
    position: absolute;
    display: flex;
    align-items: flex-end;
    font-size: 17px;
    cursor: pointer;
    transform: translateX(-50%);
    z-index: 1;
}

.timeline-game-icon svg {
    font-size: 17px;
    margin: -10px 0;
}

.timeline-game-icon.away svg {
    margin: -17px 0;
}

.timeline-game-icon.away img {
    margin: -20px 0 !important;
}

.timeline-game-icon.penalty-shot {
    color: rgb(125, 150, 200);
}

.timeline-score {
    position: absolute;
    bottom: -10px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 28px;
    font-size: 12px;
    padding: 1px 0;
    gap: 5px;
    background-color: rgb(120, 30, 250);
    border-radius: 3px;
    color: white;
    border: 1px solid rgba(0, 0, 0, 0.5);
    transform: translateX(-50%);
}

.away .timeline-score {
    font-size: 12px;
    bottom: unset;
    top: 2px;
}

.timeline-score svg {
    font-size: 12px;
    color: white;
}

.icon-line {
    position: absolute;
    top: 10px;
    left: 50%;
    border-right: 1px solid rgba(255, 255, 255, 0.3);
}

.away .icon-line {
    top: unset;
    bottom: -2px;
}

.timeline-phase-single {
    position: absolute;
    top: calc(50% - 5px);
    display: flex;
    align-items: flex-end;
    height: 16px;
    width: 4px;
    border: 2px solid white;
    border-right: none;
    cursor: pointer;
    z-index: 10;
    transform: translateX(-50%);
}

.timeline-phase-single.end {
    border: 2px solid white;
    border-left: none;
}

.timeline-phase-text {
    position: absolute;
    top: 35px;
    width: 70px;
    padding: 3px 5px;
    transform: translateX(-50%);
    text-align: center;
    background-color: white;
    color: var(--dark-blue);
    border: 1px solid rgba(0, 0, 0, 0.5);
    visibility: hidden;
    z-index: 12;
}

.timeline-end .timeline-phase-text {
    transform: translateX(-90%);
}

.timeline-hover-info {
    position: absolute;
    bottom: 12px;
    width: 120px;
    visibility: hidden;
    background-color: white;
    color: var(--dark-blue);
    border: 1px solid rgba(0, 0, 0, 0.5);
    cursor: pointer;
    transform: translateX(-50%);
}

.timeline-game-icon.away .timeline-hover-info {
    top: 25px;
    bottom: unset;
}

.timeline-start .timeline-hover-info {
    transform: translateX(-10%);
}

.timeline-end .timeline-hover-info {
    transform: translateX(-90%);
}

.timeline-hover-title {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 5px;
    background-color: rgb(5, 25, 50);
}

.timeline-hover-title img {
    height: 15px;
}

.timeline-hover-time-icon {
    display: flex;
    align-items: center;
    gap: 12px;
    font-size: 12px;
    color: white;
}

.timeline-single-desc {
    padding: 3px;
    font-size: 12px;
}

.timeline-team-logo-cont {
    position: absolute;
    top: 37px;
    left: 0;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 80px;
    z-index: 0;
}

.timeline-team-logo-cont img {
    height: 25px;
}

/* HOVER */
.timeline-game-icon:hover .timeline-hover-info {
    visibility: visible;
}
.timeline-game-icon:hover {
    color: var(--dark-yellow);
    z-index: 10!important;
}
.timeline-game-icon:hover .icon-line,
.timeline-phase-single:hover {
    border-color: var(--dark-yellow);
}
.timeline-event-option:hover{
    color: var(--dark-yellow);
}
.timeline-game-icon:hover .timeline-score {
    background-color: var(--dark-yellow);
    color: var(--dark-blue);
}
.timeline-phase-single:hover .timeline-phase-text {
    visibility: visible;
}