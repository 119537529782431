.dropdown {
    position: relative;
    font-size: 13px;
}
button[disabled].dropdown-btn {
    font-style: italic;
}
span.dropdown-arrow {
    margin-top: 3px;
}
ul.centered li {
    text-align: center;
}
ul.dropdown-content {
    width: auto;
    list-style: none;
    margin: 0;
    padding-inline: 0;
    display: none;
    position: absolute;
    top: 100%;
    background-color: var(--bg-color);
    max-width: 275px;
    overflow-x: hidden;
    overflow-y: auto;
    max-height: 75vh;
    min-width: 100%;
    box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2);
    user-select: none;
    z-index: 1;
}
.dropdown-content li {
    padding: 8px 8px;
    text-decoration: none;
    white-space: nowrap;
    display: block;
    cursor: pointer;
}
.dropdown.open .dropdown-content {
    display: block;
}
.dropdown-content li.active {
    color: var(--dark-yellow);
}
.dropdown-btn:focus,
.dropdown-btn:hover,
.dropdown.open .dropdown-btn,
.dropdown-content li:hover {
    background-color: rgba(255, 255, 255, 0.2);
}