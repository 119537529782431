.user-group-info-cont {
    padding-left: 10px;
    width: 100%;
    display: flex;
    flex-direction: column;
}

.user-group-info {
    margin-bottom: 32px;
}

.user-group-name {
    display: flex;
    align-items: center;
    font-size: 30px;
    margin-bottom: 15px;
    gap: 12px;
}

.user-group-logo-icon {
    font-size: 42px;
}

.group-user-title {
    font-size: 14px;
    font-weight: 600;
    margin-bottom: 12px;
    color: var(--grey);
}

.group-user-single {
    height: 50px;
    float: left;
    display: flex;
    align-items: center;
    justify-content: space-between;
    min-width: 240px;
    background-color: #101525;
    box-shadow: 0px 2px 7px black;
    margin-bottom: 20px;
    margin-right: 12px;
    border-radius: 3px;
    padding: 0 10px;
}

.group-user-name {
    display: flex;
    align-items: center;
    gap: 10px;
    margin-right: 20px;
}

.group-user-icon svg {
    color: var(--grey);
    font-size: 32px;
}

.group-user-remove {
    font-size: 14px;
    margin-top: 3px;
    cursor: pointer;
}

.add-user-cont {
    display: flex;
    flex-direction: column;
}

.add-user-search {
    margin-bottom: 7px;
    display: flex;
}

.add-user-search input[type="text"] {
    min-width: 200px;
    height: 35px;
    border-radius: 3px;
    border: 1px solid rgba(255, 255, 255, 0.15);
    background-color: rgba(0,0,0,0.25);
    padding: 0 7px;
    margin-right: 10px;
    font-size: 14px;
    flex-grow: 1;
}

.add-user-search input[type="text"]:focus-visible {
    outline: 1px solid var(--light-blue);
}

.add-user-result-name {
    display: flex;
    align-items: center;
    height: 32px;
    width: 100%;
    margin-bottom: 15px;
    margin-left: 5px;
    gap: 15px;
}

.add-user-profile {
    display: flex;
    justify-content: center;
    align-items: center;
    color: var(--grey);
}

.add-user-profile-icon {
    font-size: 32px;
}

.add-user-check {
    font-size: 15px;
    color: var(--button-green);
}

.add-user-checkbox,
.add-user-uncheckbox {
    margin-left: 24px;
    font-size: 17px;
}
.add-user-checkbox {
    color: var(--button-green);
}

.add-user-result-name button{
    font-size: 14px;
}

.user-in-list {
    height: 24px;
    visibility: hidden;
    color: var(--warning-text-red);
    font-weight: 600;
}

.user-in-list .visible {
    visibility: visible;
}

.group-no-user {
    color: var(--grey);
    margin-bottom: 10px;
}

/* HOVER */
.group-user-remove:hover {
    color: var(--dark-yellow);
}