.paging-cont {
    margin: 0 auto;
    display: flex;
    justify-content: center;
    width: fit-content;
    gap: 2px;
    border-top: 1px solid rgb(50, 50, 50);
    border-bottom: 1px solid rgb(50, 50, 50);
    padding: 5px 0;
}

.paging-cont button {
    padding: 8px 12px;
    font-size: 14px;
    border-radius: 2px;
    border: none;
}

.active-page.page-btn button {
   background-color: var(--light-blue);
}

.page-number-dots {
    display: flex;
    align-items: end;
    padding-bottom: 5px;
}

button.page-next-previous {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 3px;
    width: 70px;
    font-size: 12px;
    background-color: rgba(0,0,0,.35);
}

button.page-next-previous:hover {
    background-color: var(--light-blue);
}

button[disabled].page-next-previous {
    background-color: rgba(0,0,0,.3);
}

/* MEDIA QUERY */
@media screen and (max-width: 768px) {
    .paging-cont button {
        padding: 8px 10px;
        font-size: 12px;
    }
    button.page-next-previous {
        font-size: 11px;
    }
}

@media screen and (max-width: 480px) {
    .paging-cont {
        padding: 3px 0;
        gap: 1px;
    }
    .paging-cont button {
        padding: 7px;
        font-size: 11px;
    }
    button.page-next-previous {
        width: 55px;
        gap: 2px;
    }
}