.profile-cont {
    display: flex;
    align-items: center;
    width: 100%;
    flex-direction: column;
    gap: 12px;
}

.logged-in-as {
    border-bottom: 1px solid white;
    padding-bottom: 7px;
    margin-bottom: 12px;
    font-size: 1em;
    text-transform: uppercase;
    font-weight: bold;
}

.profile-info-cont {
    min-width: 90%;
    max-width: 850px;
    padding: 25px;
    border: 1px solid rgba(0,0,0,0.5);
    box-shadow: 9px 12px 12px black;
}

.profile-pic-and-name {
    display: flex;
    margin-bottom: 21px;
}

.profile-pic-cont {
    display: flex;
    align-items: center;
    height: 140px;
    width: 125px;
    padding: 24px;
    margin-right: 24px;
    border: 1px solid rgba(255, 255, 255, 0.25);
}

.profile-details-cont {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.pa-cont {
    width: 100%;
    display: flex;
    justify-content: space-between;
}

.pa-single {
    display: flex;
    align-items: center;
    margin-right: 25px;
}

.pa-icon {
    font-size: 35px;
    margin-right: 5px;
    color: var(--dark-yellow);
}

.pa-info {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 3px;
}

.pa-info-title {
    color: rgba(255, 255, 255, 0.5);
}

.user-icon {
    font-size: 95px;
}

.user-name {
    text-align: left;
    font-size: 2em;
}

.log-out-button {
    margin-top: 12px;
    width: fit-content;
}

.profile-build-version-text {
    color: grey;
    font-style: italic;
    font-size: small;
    margin-top: 20px;
}

/* CHANGE PASSWORD */
.change-password-cont {
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 275px;
    margin-top: 7px;
    margin-bottom: 7px;
}

.change-password-title {
    width: 100%;
    text-align: center;
    font-size: 17px;
    margin-bottom: 25px;
}

.change-password-warning {
    min-height: 27px;
    padding: 3px 0;
    margin-bottom: 3px;
}

.change-password-btn {
    width: fit-content;
}