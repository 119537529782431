.create-stream-cont {
    width: 100%;
    padding-bottom: 10px;
    height: fit-content;
}

.stream-profile-cont {
    width: 100%;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-between;
    text-align: center;
    cursor: pointer;
}

.stream-profile {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 32px;
}

.create-stream-key {
    margin-top: 5px;
    font-size: 15px;
}

.stream-time-info {
    margin-top: 10px;
    color: rgba(255, 255, 255, 0.7);
}

.stream-time-info.margin-bottom {
    margin-bottom: 5px;
}

.stream-duration-cont {
    width: 100%;
}

.stream-duration-list-cont {
    display: flex;
    align-items: center;
    gap: 10px;
}

.stream-duration-list {
    display: flex;
    gap: 10px;
}

.stream-duration-list button {
    font-size: 12px;
}

.stream-duration-single {
    display: flex;
    align-items: center;
    height: 30px;
    padding: 0 7px;
    border-radius: 3px;
    border: 1px solid rgba(255, 255, 255, 0.5);
    cursor: pointer;
}

.stream-duration-single.active {
    border-color: var(--dark-yellow);
}

.stream-custom-duration {
    display: flex;
    align-items: center;
    gap: 5px;
    height: 30px;
    padding: 0 7px;
    border-radius: 3px;
    border: 1px solid rgba(255, 255, 255, 0.5);
    cursor: pointer;
}

.stream-custom-duration.active {
    border-color: var(--dark-yellow);
}

.stream-custom-duration input[type="number"] {
    text-align: center;
    width: 30px;
    height: 25px;
    padding-top: 3px;
    border: 1px solid transparent;
    border-radius: 3px;
    background-color: rgba(255, 255, 255, 0.1);
}

.stream-custom-duration input[type="number"]:focus-visible {
    outline: 1px solid var(--light-blue);
}

.duration-and-option-cont {
    display: flex;
    margin-top: 7px;
}

.time-and-icon {
    display: flex;
    align-items: center;
    height: 100%;
}

.duration-hours {
    display: flex;
    align-items: center;
    padding-top: 3px;
    margin: 0 5px;
}

.duration-chosen,
.profile-chosen {
    background-color: var(--light-blue);
}

.time-and-icon input[type="number"] {
    display: flex;
    width: 32px;
    height: 100%;
    text-align: center;
    border: 1px solid transparent;
    background-color: transparent;
    font-size: 1.2em;
    color: white;
    cursor: text;
    border-radius: 3px;
    background-color: rgba(255, 255, 255, 0.1);
    padding: 5px;
}

.time-and-icon input[type="number"]:focus-visible {
    outline: 1px solid var(--light-blue);
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

/* HOVER */
.single-duration:hover, 
.profile-list div:hover {
    background-color: var(--light-blue);
}
.time-list-arrow:hover {
    color: var(--light-blue);
}
.input-warning {
    text-align: center;
    height: 15px;
    margin-bottom: 7px;
    color: var(--warning-text-red);
    font-weight: bold;
    visibility: hidden;
}
button.stream-duration-single:hover,
.stream-custom-duration:hover {
    border-color: var(--dark-yellow);
    color: white;
}

.stream-duration-list button:hover {
    background: unset;
}
.stream-duration-list button[disabled]:hover {
    border-color: unset;
}

/* MEDIA QUERY */
@media screen and (max-width: 768px) {
    .stream-duration-list-cont {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
    }
    .stream-duration-list {
        width: 100%;
    }
    .stream-duration-list button {
        width: 100%;
        justify-content: center;
    }
    .stream-custom-duration {
        justify-content: center;
        min-width: calc(50% - 5px);
    }
}

@media screen and (max-width: 480px) {
    button.stream-duration-single,
    .stream-custom-duration {
        font-size: 11px;
    }
    .stream-duration-list {
        gap: 7px;
    }
}