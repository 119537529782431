.terms-service-content {
    max-width: 800px;
    margin: 0 auto;
    padding: 20px;
    background-color: #f5f5f5;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    text-align: left;
    overflow-y: auto;

}

.terms-service-content h1,
.terms-service-content h2 {
    color: #333;
    text-align: center;
}

.terms-service-content p,
.terms-service-content li {
    color: #666;
    line-height: 1.6;
    text-align: justify;
}

.terms-service-content ul {
    list-style-type: disc; 
    padding-left: 20px; 
}

.terms-service-content ol {
    list-style-type: decimal;
    padding-left: 20px;
}

.terms-service-content h2:before {
    content: 'Section ';
    display: none;
}