.loading-icon-main {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
}

.loading-icon-cont {
    position: relative;
    width: 30px;
    height: 30px;
    background: rgba(255, 255, 255, 0.1);
    border-radius: 50%;
    overflow: hidden;
}

.loading-icon-cont::before {
    content: "";
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
    width: 100%;
    height: 100%;
    background-image: conic-gradient(
        var(--light-blue) 90deg,
        transparent 0deg
    );
    animation: rotate 2s linear infinite;
}

.loading-icon-cont::after {
    content: "";
    position: absolute;
    inset: 3px;
    background: #101525;
    border-radius: 50%;
}

.loading-download-icon {
    position: absolute;
    z-index: 1;
    font-size: 15px;
    transform: translate(50%, 40%);
    color: var(--light-blue);
}

.processing-info-cont {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 3px;
}

.export-processing-title {
    color: var(--light-blue);
    font-weight: 600;
    font-size: 13px;
}

@keyframes rotate {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}