.search-results {
    display: flex;
    flex-direction: column;
    overflow: hidden;
}

.search-results.match-events {
    min-height: 450px;
}

.clip-entry-list {
    margin-bottom: 10px;
}

.clip-entry-list.with-paging {
    margin-bottom: 30px;
}

.refresh-playlist {
    text-align: right;
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 30px;
    gap: 3px;
    margin-bottom: 10px;
    font-size: 14px;
    cursor: pointer;
}

.refresh-playlist svg {
    font-size: 20px;
}

.refresh-check {
    font-size: 15px !important;
    color: var(--button-green);
}

.auto-refresh-label {
    text-align: center;
    margin-bottom: 5px;
    color: var(--button-green);
}

.show-total-results {
    text-align: center;
    color: var(--grey);
}
.show-total-results.padding-top {
    padding-top: 10px;
}

.no-results {
    margin-top: 25px;
    text-align: center;
    color: rgba(255, 255, 255, 0.7);
    font-style: italic;
}

@keyframes blink {
    50% { color: transparent };
}
.loading-dot { 
    animation: 1s blink infinite;
    font-size: 20px;
}
.loading-dot:nth-child(1) { 
    animation-delay: 250ms;
    margin-left: 3px;
}
.loading-dot:nth-child(2) { 
    animation-delay: 500ms;
}
.loading-dot:nth-child(3) { 
    animation-delay: 750ms;
}