.page-container {
    position: relative;
    display: flex;
    flex: 1;
    flex-direction: column;
    background-color: var(--dark-blue);
    margin: 10px;
    overflow: hidden;
    /*This is kinda hacky and feels errorprone - cant seem to get it working well without though :/*/
    max-height: calc(100vh - 20px - var(--header-size));
}
.page-title {
    display: flex;
    width: calc(100% - 2*var(--title-padding));
    height: 17px;
    flex-direction: row;
    text-transform: uppercase;
    font-weight: 700;
    margin: 12px var(--title-padding);
    color: var(--dark-yellow);
}
.page-content {
    display: flex;
    flex: 1;
    padding: var(--title-padding);
    overflow-x: hidden;
    overflow-y: auto;
}
.dual-page-container {
    position: relative;
    display: flex;
    flex: 1;
    flex-direction: row;
    overflow: hidden;
}
.page-content > .is-loading {
    font-size: larger;
    line-height: 2em;
}
.page-container.folded {
    cursor: pointer;
}
.page-container.folded .page-content {
    display: none;
    visibility: hidden;
}
.dual-page-container.page-layout-left > .page-container:nth-child(1) {
    flex-grow: 1.5;
}
.dual-page-container.page-layout-right > .page-container:nth-child(1) {
    flex-grow: 0.67;
}
.dual-page-container > .page-container:not(:first-child) {
    margin-left: 0;
}
.page-layout-buttons {
    position: absolute;
    display: flex;
    top: 15px;
    right: 20px;
}
.page-layout-buttons button {
    display: flex;
    font-weight: bold;
    line-height: 1em;
    font-size: 1.4em;
    padding: 6px 6px;
    color: rgba(255,255,255,0.65);
}
.page-layout-buttons button.back-button {
    display: none;
    text-transform: uppercase;
    font-size: 13px;
}
.page-layout-buttons button:hover, .page-layout-buttons button.active {
    color: var(--dark-yellow);
}
.dual-page-container.with-layout-buttons .page-container:nth-child(2) .page-title {
    margin-right: 95px;
}

@media screen and (max-width: 1120px) {
    /* Fold a dual-page into showing only one page when theres not enough space */
    .page-layout-buttons button {
        display: none;
    }
    .page-layout-buttons button.back-button {
        display: flex;
    }
    .dual-page-container > .page-container:nth-child(1) {
        flex-grow: 1 !important;
    }
    .dual-page-container > .page-container:nth-child(2) {
        display: none;
    }
    .dual-page-container.page-right-active > .page-container:nth-child(1) {
        display: none;
    }
    .dual-page-container.page-right-active > .page-container:nth-child(2) {
        display: flex;
        margin-left: 10px;
    }
}