.dropdown-menu-cont {
    position: relative;
    display: flex;
    align-items: flex-end;
    width: 100%;
    font-size: 14px;
    cursor: pointer;
}

.dropdown-selected {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    min-height: 27px;
    padding-bottom: 7px;
    border-bottom: 1px solid var(--dark-yellow);
}

.dropdown-list-cont {
    position: absolute;
    top: 32px;
    left: 0;
    width: 100%;
    max-height: 300px;
    background-color: black;
    z-index: 10;
    overflow-y: auto;
}

.dropdown-item-single {
    display: flex;
    align-items: center;
    height: 27px;
    padding: 0 7px;
}

.dropdown-item-single.active {
    color: var(--dark-yellow);
}

.dropdown-item-single:hover {
    background-color: var(--light-blue);
}

.children-in-dropdown {
    position: absolute;
    bottom: 9px;
    left: 90px;
}

/* HOVER */
.dropdown-selected:hover,
.dropdown-selected:hover > svg {
    color: var(--dark-yellow);
}