.navigation-tabs {
    display: inline-flex;
    flex: 1 1;
    align-items: center;
    flex-flow: row wrap;
    text-transform: uppercase;
    font-weight: 700;

    list-style: none;
    margin: calc(-1 * var(--title-padding)); /* Due to NavLink already having padding */
}
.navigation-tabs:not(.folded) {
    border-bottom: 1px solid var(--dark-yellow);
}
.navigation-tabs li a:hover {
    color: var(--dark-yellow);
}
.navigation-tabs.disabled li a:hover {
    color: unset;
}
.no-video-icon {
    font-size: 3em;
}
.navigation-tabs .fold-icon {
    display: inline-flex;
    justify-content: flex-end;
    margin-left: auto;
    margin-right: 5px;
    align-items: center;
    color: white;
    cursor: pointer;
    font-size: 22px;
    min-height: 34px;
}