.selected-category-list {
    display: flex;
    flex-direction: column;
    margin-bottom: 10px;
    gap: 10px;
}

.selected-category-single {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    margin-top: 7px;
    padding-bottom: 10px;
    border-bottom: 1px solid var(--dark-yellow);
    gap: 10px;
    cursor: pointer;
}

.selected-category-single.no-param {
    margin-top: 10px;
}

.selected-category-title {
    font-size: 14px;
}

.interviewee-single {
    display: flex;
    align-items: center;
    padding: 7px;
    gap: 7px;
    border: 1px solid rgba(255, 255, 255, 0.5);
}

.remove-interviewee-icon {
    cursor: pointer;
}

.upload-categories-list-cont {
    position: absolute;
    top: 32px;
    left: 0;
    text-align: left;
    width: 100%;
    padding-bottom: 10px;
    background-color: black;
    z-index: 10;
}

.category-single {
    padding: 7px 12px;
    color: rgba(255, 255, 255, 0.9);
    cursor: pointer;
}

.player-result-list {
    width: 100%;
}

.player-result-list ul {
    width: 100%;
    height: 100%;
    padding: 7px;
    overflow-y: scroll;
    background-color: black;
}

.player-result-single {
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 14px;
    height: 40px;
    padding: 0 7px;
    cursor: pointer;
}

.search-player-team-logo {
    height: 32px;
}

/* HOVER */
.player-result-single:hover,
.category-single:hover {
    background: var(--light-blue);
}
.add-categories-title:hover {
    color: var(--dark-yellow);
}
.interview-parameters button:hover {
    background-color: transparent;
    color: var(--dark-yellow);
}
.selected-category-single button:hover {
    background-color: unset;
    color: var(--dark-yellow);
}

/* MEDIA QUERIES */
@media screen and (max-width: 768px) {
    .interviewee-single {
        padding: 5px;
        gap: 5px;
        font-size: 12px;
    }
}

@media screen and (max-width: 480px) {
    .interviewee-single {
        flex-direction: column;
        align-items: flex-start;
        justify-content: center;
        height: auto;
        gap: 3px;
        padding: 5px;
    }
}