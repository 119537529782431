.stream-list-mobile-cont {
    display: flex;
    flex-direction: column;
    gap: 5px;
    margin-bottom: 25px;
}

.stream-list-mobile-header {
    display: flex;
    padding: 7px;
    text-transform: uppercase;
    background-color: rgba(0,0,0,.35);
    border-top: 1px solid rgba(255, 255, 255, 0.3);
    border-bottom: 1px solid rgba(255, 255, 255, 0.3);
    color: var(--grey);
    font-size: 11px;
    font-weight: 600;
}

.stream-list-mobile-header div {
    width: 50%;
}

.stream-list-mobile-data {
    position: relative;
    display: flex;
    gap: 5px;
    height: 70px;
    padding: 7px 5px;
    background-color: rgba(0,0,0,.35);
    border-radius: 3px;
}

.stream-list-mobile-title,
.stream-list-mobile-broadcast {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 5px;
    width: 50%;
    z-index: 1;
}

.stream-list-mobile-description {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: initial;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
}

.mobile-stream-team-bg {
    position: absolute;
    top: 5px;
    bottom: 5px;
    left: 5px;
    margin: auto 0;
    overflow: hidden;
    z-index: 0;
}

.mobile-stream-team-bg img {
    height: 80px;
    transform: translateX(-45%) translateY(-15%) rotate(20deg);
    filter: brightness(30%);
}

@media screen and (max-width: 480px) {
    .stream-list-mobile-data {
        font-size: 12px;
        height: 65px;
        padding: 7px 5px;
    }
    .stream-list-mobile-description,
    .stream-list-mobile-broadcast {
        font-size: 11px;
    }
}