.shares-cont {
    height: calc(100% - 30px);
    width: 100%;
}

.shared-video-single {
    width: 100%;
    min-height: 70px;
    display: flex;
    align-items: center;
    padding: 0 5px;
    font-size: 12px;
    background-color: rgba(0,0,0,.35);
    border-radius: 3px;
}

.share-submitted {
    width: 17%;
}

.share-platform {
    width: 16%;
}

.share-media {
    width: 17%;
}

.share-creator {
    width: 17%;
}

.share-url {
    width: 15%;
}

.share-status {
    width: 15%;
}

.share-delete {
    width: 3%;
}

.share-status.data {
    display: flex;
    justify-content: center;
}

.share-url.data button a {
    text-decoration: none;
    color: white;
}

.share-delete.icon {
    cursor: pointer;
}

.posting-loading {
    height: 100%;
    width: fit-content;
}

.posting-loading-message {
    display: flex;
    align-items: center;
    gap: 2px;
    margin-bottom: 5px;
    color: var(--light-blue);
    font-size: 13px;
    font-weight: 600;
}

.posting-loading-message-extra {
    display: flex;
    justify-content: flex-start;
    font-size: 11px;
    padding-left: 1px;
    color: var(--grey);
}

.posting-loading-message svg {
    font-size: 17px;
    margin-right: 2px;
}

.posting-loading-progress {
    position: relative;
    height: 17px;
    width: 150px;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    padding: 0 7px;
    border: .5px solid rgba(255, 255, 255, .25);
    border-radius: 25px;
    font-size: 10px;
}

.posting-loading-bar {
    position: absolute;
    left: 0;
    height: 100%;
    border-radius: 25px;
    background-color: var(--light-blue);
}

.posting-loading-number {
    z-index: 1;
}

.posting-success-message,
.posting-failed-message {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-grow: 1;
    color: var(--button-green);
    font-size: 12px;
    font-weight: 600;
}

.posting-failed-message {
    color: var(--warning-text-red);
}

.shares-loading {
    display: flex;
    height: 50%;
}

.no-shared-videos {
    display: flex;
    align-items: flex-end;
    justify-content: center;
    height: 75px;
    color: var(--grey);
    font-style: italic;
}

.link-to-post {
    padding: 5px 7px;
    text-decoration: none;
    color: white;
    border: 1px solid rgba(255, 255, 255, .5);
    font-size: 12px;
}

.link-to-post:hover {
    background-color: var(--light-blue);
    border-color: var(--light-blue);
    color: var(--dark-blue);
    font-weight: 500;
}

@keyframes blink {
    50% { color: transparent };
}
.share-loading-dot { 
    animation: 1s blink infinite;
    font-size: 17px;
    font-weight: 500;
}
.share-loading-dot:nth-child(1) { 
    animation-delay: 200ms;
}
.share-loading-dot:nth-child(2) { 
    animation-delay: 400ms;
}
.share-loading-dot:nth-child(3) { 
    animation-delay: 600ms;
}

/* HOVER */
.share-url.data button:hover a {
    color: var(--dark-blue);
}
.share-delete.icon:hover {
    color: var(--dark-yellow);
}