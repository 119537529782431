.privacy-policy-content {
    max-width: 800px;
    margin: 0 auto;
    padding: 20px;
    background-color: #f5f5f5;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    text-align: left;
    overflow-y: auto;

}

.privacy-policy-content h1,
.privacy-policy-content h2 {
    color: #333;
    text-align: center;
}

.privacy-policy-content p,
.privacy-policy-content li {
    color: #666;
    line-height: 1.6;
    text-align: justify;
}

.privacy-policy-content ul {
    list-style-type: disc;
    padding-left: 20px; 
}

.privacy-policy-content ol {
    list-style-type: decimal; 
    padding-left: 20px;
}

.privacy-policy-content h2:before {
    content: 'Section ';
    display: none;
}