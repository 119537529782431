.loading-icon {
    width: 100%;
    height: 100%;
    display: flex;
    flex: 1;
}
.loading-icon > div {
    position: relative;
    top: 50%;
    left: 50%;
    margin-left: -25px;
    width: 40px;
    height: 40px;
    border: 6px solid rgba(0,0,0,0.25);
    border-top-color: var(--light-blue);
    border-radius: 50%;
    
    animation: spin 1s linear infinite;
}
@keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
}
