.pre-post-roll-cont {
    position: relative;
    width: 100%;
}

.chosen-roll-cont {
    display: flex;
    align-items: center;
    justify-content: space-between;
    cursor: pointer;
    padding-top: 5px;
    padding-bottom: 10px;
    border-bottom: 1px solid var(--dark-yellow);
}

.chosen-pre-roll-info,
.chosen-post-roll-info {
    display: flex;
    align-items: center;
    padding: 5px;
    gap: 7px;
}

.chosen-roll-name {
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 5px;
}

.roll-list {
    width: 100%;
    position: absolute;
    top: calc(100% + 5px);
    max-height: 420px;
    overflow-y: scroll;
    margin:auto;
    background: black;
    z-index: 10;
}

.roll-single {
    display: flex;
    min-height: 74px;
    padding: 12px;
    gap: 7px;
    cursor: pointer;
    align-items: center;
}

.roll-list > *:not(:last-child) {
    border-bottom: 1px solid rgba(255, 255, 255, 0.3);
}

.chosen-roll-info {
    display: flex;
    align-items: center;
    gap: 10px;
}

.chosen-roll-info div {
    height: 50%;
}

.roll-single img,
.chosen-roll-info img {
    height: 50px;
}

.roll-not-set {
    display: flex;
    align-items: center;
    height: 40px;
    padding-left: 12px;
    cursor: pointer;
}

.roll-single.active,
.roll-not-set.active {
    background: var(--light-blue);
}

.no-roll-found {
    display: flex;
    align-items: center;
    height: 40px;
    padding: 0 12px;
    color: rgba(255, 255, 255, 0.5);
}

.roll-single:hover,
.roll-not-set:hover {
    background: var(--light-blue);
    color: var(--dark-blue);
}

.chosen-roll-cont:hover svg {
    color: var(--dark-yellow);
}

@media screen and (max-width: 480px) {
    .roll-list {
        font-size: 11px;
    }
    .roll-list > * {
        min-height: 49px;
        padding: 7px;
    }
    .roll-single img,
    .chosen-roll-info img {
        height: 35px;
    }
}