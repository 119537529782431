.user-groups-cont {
    position: relative;
    width: 100%;
}

.user-groups-search {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    margin-bottom: 25px;
    font-size: 14px;
    gap: 9px;
}

.user-groups-search input[type="text"] {
    width: 175px;
    height: 30px;
    border-radius: 3px;
    border: 1px solid rgba(255, 255, 255, 0.2);
    background-color: #101525;
    padding: 0 7px;
    font-size: 14px;
}

.user-groups-search input[type="text"]:focus-visible {
    outline: 1px solid var(--light-blue);
}

.user-type-title {
    width: 50%;
    cursor: pointer;
}

.user-group-single {
    display: flex;
    background-color: #101525;
    padding: 15px 12px;
    cursor: pointer;
}

.user-group-single.active {
    background-color: var(--hover-blue);
}

.no-group-selected-icon {
    font-size: 47px;
}

.user-groups-table {
    position: relative;
    display: flex;
    flex-direction: column;
    gap: 5px;
    height: calc(100% - 100px);
}

.user-groups-table div {
    text-align: center;
}

.user-group-single-name {
    width: 30%;
}

.user-group-length {
    width: 18%;
}

.user-group-channel-list {
    width: 34%;
}

.user-group-created {
    width: 18%;
}

.user-group-logo-name {
    display: flex;
    align-items: center;
    gap: 10px;
}

.table-data-group-icon {
    font-size: 20px;
}

.channel-list {
    min-width: 175px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 3px;
}

.user-group-single-name.data {
    margin-left: 7px;
}

.user-group-channel-list.data {
    display: flex;
    justify-content: center;
}

.only-available {
    display: flex;
    flex-direction: column;
    width: 100%;
    text-align: center;
    font-size: 20px;
    gap: 15px;
}

.only-available img {
    height: 55px;
}

.create-new-user {
    position: absolute;
    display: flex;
    align-items: center;
    gap: 5px;
    top: 0;
    right: 0;
    margin-top: 12px;
    margin-right: 10px;
    font-size: 12px;
}

.create-new-user svg {
    font-size: 17px;
    margin-bottom: 2px;
}

/* CREATE NEW USER */
.create-new-user-cont {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 100%;
    padding-bottom: 10px;
    height: 100%;
}

.create-new-user-form {
    display: flex;
    flex-direction: column;
    gap: 25px;
    width: 100%;
}

.create-user-no-permission {
    margin: 0 auto;
    margin-top: 25px;
    color: var(--grey);
    font-style: italic;
}

/* HOVER */
.user-group-single:hover {
    background-color: var(--hover-blue);
}