.video-metadata-cont {
    padding: 7px;
    background: #101525;
    box-shadow: 0 0 12px black;
    margin-bottom: 30px;
}

.vm-thumbnail {
    height: 135px;
    padding-top: 10px;
    margin-top: -15px;
}

.thumbnail-opt-cont button {
    min-width: 90px;
}

.versus {
    text-transform: lowercase;
    margin: 0 7px;
}

.vm-check-icon {
    display: flex;
    font-size: 1.2em;
}

.match-link {
    cursor: pointer;
}

a.match-link:visited {
    color: unset;
}

.metadata-and-permission-btn {
    display: flex;
    gap: 10px;
}

.editing-controls-and-info {
    margin-top: 5px;
    margin-bottom: 21px;
    padding: 5px 12px;
    background: #101525;
    box-shadow: 0 0 10px black;
}

.ori-event-info {
    width: 100%;
    display: flex;
    justify-content: center;
    gap: 15px;
    margin: 12px 0;
}

/* Edit Metadata */
.edit-metadata-modal {
    flex-direction: column;
    overflow: unset;
}

.edit-metadata-modal form {
    padding-top: 15px;
}

.edit-metadata-modal .confirm-cancel-btn-cont {
    border-top: 1px solid white;
    margin-top: 5px;
    padding-top: 15px;
    padding-left: 15px;
    padding-right: 30px;
}

.edit-metadata-modal .confirm-cancel-btn-cont > *:last-child {
    margin-left: auto;
}

.edit-metadata-cont {
    display: flex;
    flex-direction: column;
    gap: 25px;
    margin: 0 auto;
    padding-left: 15px;
    padding-right: 15px;
}

.edit-metadata-cont > .warning-msg {
    margin-top: -20px;
}

.edit-metadata-title {
    text-align: center;
    font-size: 1.5em;
}

.checkbox-options {
    display: flex;
    margin-top: 5px;
}

.checkbox-options div {
    font-size: 15px;
    margin-right: 25px;
    cursor: pointer;
}

.checkbox-options svg {
    margin-right: 5px;
    margin-bottom: -2px;
}

.video-metadata-tag {
    display: flex;
    flex-direction: column;
    font-size: 12px;
}
.video-metadata-tag span {
    text-align: right;
    font-size: 14px;    
}
.video-metadata-tags-cont {
    padding-top: 2px;
    margin-top: 2px;
    margin-bottom: 5px;
    border-top: .5px solid rgba(255, 255, 255, 0.2);
}
.video-metadata-tags-prop {
    display: flex;
}
.video-metadata-tags-prop div:nth-child(1) {
    min-width: 110px;
    color: rgba(255, 255, 255, 0.7);
}
.video-metadata-tags-prop div:nth-child(2) {
    margin-left: 3px;
    margin-right: 10px;
}
.video-metadata-tags-prop div:nth-child(3) {
    width: 100%;
    text-align: right;
}

.frontend-url-link {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    gap: 10px;
}

.frontend-url-link a:visited {
    color: var(--dark-yellow);
}

.frontend-url-share {
    position: relative;
    text-align: right;
    cursor: pointer;
}

.frontend-url-share-btn {
    display: flex;
    align-items: center;
    gap: 3px;
    color: white;
    font-size: 13px;
    font-style: italic;
    opacity: 1;
    transition: opacity .2s linear;
}

.frontend-url-share-btn svg {
    font-size: 18px;
}

.share-video-cont {
    position: absolute;
    top: -3px;
    bottom: 0;
    right: 0;
    display: flex;
    align-items: center;
    height: fit-content;
    gap: 7px;
    padding: 2px 5px;
    opacity: 0;
    transform: translateX(100%);
    width: fit-content;
    z-index: 0;
    transition: transform .2s linear, opacity .2s linear;
    pointer-events: none;
    color: white;
}

.copy-link-icon {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 25px;
    height: 25px;
    background-color: grey;
    border-radius: 50%;
}

.match-link-game {
    display: flex;
    align-items: center;
    gap: 15px;
}

.match-link-game-info {
    display: flex;
    align-items: center;
    gap: 5px;
}

.match-link-game-info img {
    height: 22px;
}

.match-link-game-date {
    display: flex;
    align-items: center;
    gap: 5px;
    color: var(--grey);
    font-size: 12px;
    margin-right: 10px;
}

.match-link-game-date svg {
    font-size: 17px;
    color: var(--light-blue);
}

.match-link-to-match {
    display: flex;
    align-items: center;
    color: white;
    text-decoration: none;
}

.match-link-to-match svg {
    font-size: 17px;
    margin-top: 1px;
}

/* HOVER */
.match-link:hover,
a.match-link:hover,
.checkbox-options div:hover {
    color: var(--dark-yellow);
}
.frontend-url-share:hover .frontend-url-share-btn {
    opacity: 0;
}
.frontend-url-share:hover .share-video-cont {
    opacity: 1;
    transform: translateX(0%);
    pointer-events: visible;
}
.share-social-media-btn:hover .share-social-media-name {
    display: block;
}
.match-link-to-match:hover {
    color: var(--dark-yellow);
    filter: unset;
}

.video-total-views {
    margin-bottom: 10px;
}

/* MEDIA QUERY */
@media screen and (max-width: 480px) {
    .metadata-and-permission-btn {
        flex-direction: column;
        gap: 7px;
    }
    .video-metadata-tag span {
        font-size: 12px;
    }
    .edit-metadata-title {
        font-size: 17px;
        font-weight: 600;
    }
}