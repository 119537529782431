.upload-details-data-cont {
    width: 75%;
    font-size: 15px;
}

.cancel-confirmation-cont {
    display: flex;
    align-items: center;
    font-size: 15px;
    margin-top: 25px;
    gap: 10px;
}

.cancel-confirmation-cont button {
    width: 60px;
}

.upload-progress-bar {
    position: relative;
    width: 500px;
    height: 30px;
    margin: 25px 0;
    background: black;
    border: 1px solid rgba(255, 255, 255, 0.2);
    border-radius: 1.5em;
    overflow: hidden;
}

.upload-progress-cont {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.progress-bar-fill {
    width: 0;
    height: 100%;
    background: var(--dark-yellow);
    border: 1px solid rgba(255, 255, 255, 0.2);
}

.progress-bar-number {
    position: absolute;
    top: 50%;
    right: 7px;
    transform: translateY(-50%);
    font-size: 1.2em;
    font-weight: 500;
    color: darkgray;
}

.progress-bar-number.completed {
    color: var(--dark-blue);
}

.upload-status-title {
    font-size: 25px;
    margin-bottom: 32px;
    padding: 5px 10px;
    color: var(--dark-blue);
}

.upload-status-title.uploading {
    background-color: var(--light-blue);
}

.upload-status-title.completed {
    background-color: var(--button-green);
}

.upload-status-title.failed {
    background-color: var(--button-red);
}

@media screen and (max-width: 768px) {
    .upload-status-title {
        font-size: 20px;
        margin-bottom: 15px;
        padding: 3px 10px;
    }
    .upload-progress-bar {
        width: 350px;
        height: 30px;
        margin: 10px 0;
        margin-bottom: 20px;
    }
    .cancel-confirmation-cont {
        font-size: 12px;
        margin-top: 20px;
    }
}

@media screen and (max-width: 480px) {
    .upload-progress-bar {
        width: 300px;
        height: 25px;
        margin: 5px 0;
        margin-bottom: 15px;
    }
    .progress-bar-number,
    .cancel-confirmation-cont {
        font-size: 10px;
    }
    .cancel-confirmation-cont button {
        width: auto;
    }
}