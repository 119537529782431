.feedback-cont {
    position: absolute;
    top: 3px;
    left: 0;
    right: 0;
    margin: auto;
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: fit-content;
    padding: 5px 9px;
    font-size: 1.2em;
    background: var(--button-green);
    color: var(--dark-blue);
    border: 1px solid rgba(255, 255, 255, 0.25);
    border-radius: 5px;
    z-index: 10;
}

.feedback-warning {
    background: var(--button-red);
}

.feedback-icon-msg {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
}

.feedback-icon {
    font-size: 27px;
    margin-right: 7px;
    color: var(--dark-blue);
}

.feedback-remove {
    margin-left: 17px;
    cursor: pointer;
}

.feedback-msg-link {
    display: flex;
    justify-content: center;
    min-width: 90px;
    margin-left: 3px;
    text-decoration: underline;
    color: black;
}

.feedback-msg-link:visited {
    color: unset;
}

/* EXPORT VIDEO */
.export-banner-cont {
    position: absolute;
    bottom: 20px;
    right: 20px;
    width: fit-content;
    padding: 15px;
    font-size: 1.2em;
    background-color: rgb(10, 23, 39);
    color: white;
    border: 1px solid rgba(255, 255, 255, 0.25);
    border-radius: 3px;
    z-index: 10;
}

.export-banner-close {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    gap: 2px;
    margin-bottom: 12px;
    color: rgba(255, 255, 255, 0.7);
    font-size: 12px;
    cursor: pointer;
}

.export-banner-close svg {
    margin-top: 2px;
}

.export-banner-close:hover {
    color: rgba(255, 255, 255, 0.9);
}