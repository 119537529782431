.permission-cont {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    height: 100%;
}

.permission-clip-title {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    padding-bottom: 10px;
    border-bottom: 1px solid rgba(255, 255, 255, 0.25);
}

.permission-clip-title img {
    height: 42px;
    margin-right: 12px;
}

.available-permission-search {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 15px 0;
    width: 650px;
    font-size: 14px;
    gap: 9px;
}

.available-permission-search input[type="text"] {
    width: 140px;
    height: 30px;
    border-radius: 3px;
    border-bottom: 2px solid transparent;
    border-bottom: 1px solid rgba(255, 255, 255, 0.25);
    background-color: rgba(0,0,0,0.25);
    padding: 0 7px;
    font-size: 14px;
}

.available-permission-search input[type="text"]:focus-visible {
    outline: 1px solid var(--light-blue);
}

.available-permission-search button {
    position: absolute;
    top: 0;
    right: 0;
    font-size: 14px;
}

.permission-cont button {
    width: fit-content;
}

/* Table */
.user-permission-cont {
    display: flex;
    flex-direction: column;
    gap: 5px;
    width: 90%;
    height: calc(100% - 150px);
}

.user-permission-single {
    display: flex;
    align-items: center;
    padding: 3px 0;
    border-radius: 3px;
    background-color: #101525;
}

.permission-user-group {
    text-align: center;
    width: 40%;
}

.user-permission-type {
    text-align: center;
    width: 20%;
}

.permission-list-user {
    display: flex;
    align-items: center;
    height: 100%;
    font-size: 15px;
}

.permission-list-profile {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 40px;
    width: 45px;
    padding: 2px;
    /* border: 1px solid rgba(255, 255, 255, 0.25); */
}

.permission-list-profile img {
    height: 24px;
}

.permission-check-icon {
    font-size: 20px;
    color: var(--button-green);
    cursor: pointer;
}

.permission-uncheck-icon {
    font-size: 15px;
    cursor: pointer;
}

.permission-close-btn {
    margin-top: 20px;
}

.letter-sort-icon {
    margin-left: 7px;
}

.letter-sort-icon {
    margin-bottom: -3px;
}

.permission-uncheck-icon:hover {
    color: var(--dark-yellow);
}