.statistic-cont {
    position: relative;
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 20px;
    padding-bottom: 10px;
    height: fit-content;
}

.statistic-filters-cont {
    display: flex;
    align-items: center;
    gap: 10px;
}

.statistic-single-cont {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 15px;
}

.statistic-graph iframe {
    width: 100%;
    height: 320px;
}

.statistic-graph-small iframe {
    width: 500px;
    height: 320px;
}

.statistic-number {
    width: 30%;
}

.statistic-table {
    width: 70%;
}

.statistic-table iframe,
.statistic-number iframe {
    width: 100%;
    height: 320px;
}

.statistic-period-cont {
    position: relative;
    display: flex;
    align-items: center;
    height: 42px;
    min-width: 200px;
    cursor: pointer;
    border: 1px solid rgba(255, 255, 255, 0.2);
    border-radius: 2px;
}

.smaller-period-filter .statistic-period-cont {
    border: 1px solid rgba(255, 255, 255, 0.35);
    height: 27px;
}

.statistic-period-cont.active {
    border-color: var(--dark-yellow);
}

.statistic-period {
    display: flex;
    align-items: center;
    height: 100%;
    width: 100%;
    gap: 7px;
    padding: 0 5px;
    font-size: 12px;
}

.statistic-flex {
    display: flex;
    width: 100%;
    gap: 15px;
}

.statistic-period svg {
    margin-top: -2px;
    font-size: 18px;
    color: rgba(255, 255, 255, 0.8);
}

.statistic-period-options-cont {
    position: absolute;
    top: 43px;
    left: -2px;
    right: -2px;
    width: fit-content;
    display: flex;
    background-color: black;
    border: 1px solid rgba(255, 255, 255, 0.15);
    z-index: 1;
}

.smaller-period-filter .statistic-period-options-cont {
    top: 30px;
}

.statistic-period-options {
    width: 125px;
    margin-right: 25px;
    border-right: 1px solid rgba(255, 255, 255, 0.15);
}

.period-option-single {
    padding: 7px 10px;
}

.period-option-single.active {
    background-color: var(--light-blue);
    color: var(--dark-blue);
    font-weight: 500;
}

@keyframes flipHorizontal {
    0%   {transform: rotateY(0deg)}
    10%  {transform: rotateY(90deg)}
    20%  {transform: rotateY(180deg)}
    30%  {transform: rotateY(90deg)}
    40%  {transform: rotateY(0deg)}
    50%  {transform: rotateY(90deg)}
    60%  {transform: rotateY(0deg)}
}

@keyframes slideLeft {
    60%  {background-color: var(--dark-blue);}
    65%  {background-color: var(--light-blue);}
    70%  {transform: translateX(0%)}
    80%  {transform: translateX(-150%)}
    100% {
        transform: translateX(-150%);
        background-color: var(--light-blue);
    }
}

@keyframes slideRight {
    60%  {background-color: var(--dark-blue);}
    65%  {background-color: var(--light-blue);}
    70%  {transform: translateX(0%)}
    80%  {transform: translateX(150%)}
    100% {
        transform: translateX(150%);
        background-color: var(--light-blue);
    }
}

/* HOVER */
.period-option-single:hover {
    background-color: var(--light-blue);
    color: var(--dark-blue);
}
.statistic-period:hover svg {
    filter: brightness(80%);
}

@media screen and (max-width: 480px) {
    .statistic-period {
        gap: 3px;
    }
    .statistic-period svg {
        font-size: 15px;
    }
    .statistic-period-cont {
        height: 35px;
        width: 170px;
        font-size: 11px;
    }
    .statistic-period-options-cont {
        display: flex;
        flex-direction: column;
    }
    .statistic-period-options {
        width: 100%;
        margin-right: 0;
        margin-bottom: 10px;
    }
}