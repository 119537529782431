
.graphic-packages-cont {
    display: flex;
    flex-direction: column;
    width: 100%;
    gap: 7px;
    height: fit-content;
}

.graphic-package-single {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 60px;
    padding: 0 15px;
    background-color: rgba(0, 0, 0, 0.35);
    border-radius: 3px;
    cursor: pointer;
}

.graphic-package-single.active {
    background-color: var(--hover-blue);
}

.graphic-package-name {
    display: flex;
    align-items: center;
    height: 100%;
    font-size: 17px;
}

.graphic-template-name {
    display: flex;
    align-items: center;
    height: 100%;
    width: 125px;
    gap: 7px;
    font-size: 12px;
}

.graphic-template-name span {
    color: var(--grey);
}

.create-graphic-package-cont {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    min-height: 100%;
    height: fit-content;
    padding-bottom: 10px;
}

.graphic-package-form {
    display: flex;
    flex-direction: column;
    gap: 35px;
    padding-bottom: 20px;
}

.multiple-options-cont {
    display: flex;
    flex-direction: column;
    gap: 12px;
    padding-top: 5px;
    padding-bottom: 15px;
    padding-left: 2px;
    /* border-bottom: 1px solid var(--dark-yellow); */
    font-size: 15px;
}

.single-multiple-option-cont {
    display: flex;
    align-items: center;
    width: fit-content;
}

.single-multiple-option-name {
    width: 175px;
}

.intro-options {
    margin-bottom: 10px;
}

.boolean-input-title {
    width: 175px;
    color: var(--dark-yellow);
}

.select-check-icon {
    display: flex;
    align-items: center;
    height: 100%;
    font-size: 18px;
    cursor: pointer;
}

.select-check-icon.active {
    color: var(--dark-yellow);
}

.color-input-cont {
    position: relative;
    display: flex;
    align-items: center;
    width: 100%;
    gap: 10px;
}

.color-input {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    width: 30px;
    height: 33px;
    background-color: transparent;
    padding: 0;
    border: none;
    cursor: pointer;
}
.color-input::-webkit-color-swatch {
    border-radius: 2px;
    border: none;
}
.color-input::-moz-color-swatch {
    border-radius: 2px;
    border: none;
}

.color-hex-text {
    width: 70px;
}

.restore-default-color {
    display: flex;
    align-items: center;
    margin-left: 45px;
    gap: 3px;
    font-size: 12px;
    cursor: pointer;
}

.restore-default-color svg {
    font-size: 15px;
}

.text-preview-box {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 90px;
    height: 100%;
    font-size: 15px;
    font-weight: 500;
    border: 1px solid rgba(175, 175, 175, 0.25);
    cursor: pointer;
}

.package-input-description {
    margin-top: -3px;
    margin-bottom: 7px;
    color: rgba(255, 255, 255, 0.5);
    font-size: 12px;
}

/* TODO might be used when has text size */
.text-size-input-cont input[type="number"]::-webkit-outer-spin-button,
.text-size-input-cont input[type="number"]::-webkit-inner-spin-button {
  -webkit-appearance: inner-spin-button;
  opacity: 1;
}

/* GRAPHIC PACKAGE DETAILS */
.graphic-package-details-cont {
    width: 100%;
}

.package-color-value {
    display: flex;
    align-items: center;
    gap: 7px;
}

.package-color-box {
    height: 15px;
    width: 15px;
}

.params-included-events-cont {
    text-align: right;
    margin-bottom: 12px;
}

.params-value.float-right {
    display: inline;
    margin-left: 5px;
    margin-bottom: 3px;
}

/* HOVER */
.restore-default-color:hover,
.select-check-icon:hover {
    color: var(--dark-yellow);
}

.text-option-single:hover {
    background-color: var(--light-blue);
}

.graphic-package-single:hover {
    background-color: var(--hover-blue)
}