.drag-and-drop {
    min-width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border: 1px dashed grey;
    border-radius: 5px;
    background-color: transparent;
    color: grey;
}

.is-dropping {
    color: var(--light-blue);
    border-color: var(--light-blue);
    background-color: rgba(10, 50, 100, 0.5);
}

.is-dropping > * {
    pointer-events: none;
}

.upload-icon {
    font-size: 75px;
    margin-bottom: 7px;
    pointer-events: none;
}

.drag-and-drop-text {
    font-size: 15px;
    margin-bottom: 15px;
    pointer-events: none;
}

.dnd-error-msg {
    display: flex;
    align-items: center;
    text-align: center;
    min-height: 45px;
    color: var(--warning-text-red);
    font-weight: bold;
}

.input-label {
    vertical-align: center;
    text-align: center;
    padding: 5px;
    font-size: 1.2rem;
    background: var(--dark-yellow);
    color: var(--dark-blue);
    border-radius: 5px;
    cursor: pointer;
}

.is-dropping .input-label {
    background: var(--light-blue);
}

input[type="file"]{
    display: none;
}

.small .upload-icon {
    display: none;
}
.small .drag-and-drop-text {
    margin-bottom: 0;
}

.drag-and-drop.small {
    padding: 15px 0;
    margin: 0;
    gap: 5px;
}

.small .dnd-error-msg {
    margin-top: 3px;
    height: 20px;
}

.input-label:hover {
    background: var(--light-blue);
}

@media screen and (max-width: 768px) {
    .upload-icon {
        display: none;
    }
    .drag-and-drop-text,
    .dnd-error-msg {
        font-size: 12px;
    }
    .input-label {
        padding: 2px 5px;
        font-size: 1rem;
        border-radius: 3px;
    }
    .drag-and-drop {
        border-radius: 5px;
    }
}