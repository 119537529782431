.root-container {
    position: relative;
    display: flex;
    flex-direction: column;
    flex: 1;
    width: 100%;
    height: 100%;
}
.main-container {
    display: flex;
    flex-direction: row;
    flex: 1;
}