.match-associate-cont {
    position: relative;
    width: 100%;
}

.upload-selected-match-cont {
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid var(--dark-yellow);
    cursor: pointer;
}

.upload-selected-match {
    height: 30px;
    display: flex;
    align-items: center;
    margin-top: 3px;
    margin-bottom: 10px;
    gap: 5px;
    width: 100%;
}

.upload-selected-match-details {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    height: 100%;
    gap: 7px;
}

.upload-selected-match img {
    height: 21px;
}

.upload-selected-match-date {
    display: flex;
    align-items: center;
    margin-right: 15px;
    gap: 5px;
}

.match-associate-list-cont {
    position: absolute;
    top: calc(100% + 5px);
    left: -1px;
    right: 0;
    margin: auto;
    padding: 0 10px;
    width: calc(100% + 2px);
    background: var(--dark-blue);
    border: 1px solid rgba(255, 255, 255, 0.5);
    z-index: 10;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
}

.match-associate-filter-cont {
    display: flex;
    gap: 20px;
    margin: 12px 0;
}

.match-associate-filter-cont > :first-child {
    margin-right: auto;
    align-self: flex-start;
}

.match-associate-filter {
    position: relative;
    display: flex;
    align-items: center;
    height: 32px;
    width: 150px;
    background: black;
    border: 1px solid rgba(255, 255, 255, 0.3);
    cursor: pointer;
}

.match-associate-filter-title {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 100%;
    width: 100%;
    padding: 0 7px;
}

.match-associate-filter-list {
    position: absolute;
    top: 35px;
    left: 0;
    width: 100%;
    max-height: 350px;
    overflow: auto;
    background: black;
}

.match-associate-filter-single {
    display: flex;
    align-items: center;
    min-height: 25px;
    padding: 7px;
    font-size: 13px;
    border-bottom: 1px solid rgba(150,150,150, 0.3);
}

.match-date-picker {
    position: relative;
    display: flex;
    align-items: center;
    height: 32px;
}

.match-date-picker input[type="text"] {
    width: 20px;
    min-height: 20px;
    padding: 0;
    font-size: 14px;
    background: none;
    outline: none;
    border: none;
    cursor: pointer;
    caret-color: transparent;
}

.match-date-picker input[type="text"]:focus-visible {
    outline: none;
}

.match-date-picker.has-date input[type="text"] {
    width: 95px;
}

.match-date-icon {
    position: absolute;
    right: 0;
    font-size: 18px;
}

.has-date .match-date-icon {
    position: static;
    margin-right: 5px;
    font-size: 25px;
}

.remove-date-icon {
    font-size: 15px;
    margin: 0 3px;
    cursor: pointer;
}

.match-associate-games-list {
    margin-bottom: 10px;
    height: 420px;
    overflow-y: scroll;
}

.match-associate-games-single {
    display: flex;
    align-items: center;
    height: 42px;
    cursor: pointer;
    margin-bottom: 5px;
    padding-right: 5px;
    background-color: #101525;
    border-radius: 3px;
}

.match-associate-games-single img {
    height: 24px;
    margin: 0 3px;
}

.match-associate-games-single.no-match {
    justify-content: center;
    align-items: center;
}

.match-associate-team {
    width: 33%;
}

.match-associate-date {
    text-align: center;
    padding: 2px;
    width: 75px;
    margin-left: 1px;
    background: var(--dark-yellow);
    color: var(--dark-blue);
    font-size: 11px;
}

.match-associate-single-info {
    display: flex;
    align-items: center;
    justify-content: center;
    width: calc(100% - 70px);
    gap: 7px;
}

.match-associate-team.home {
    text-align: right;
}

/* HOVER */
.match-associate-filter-title:hover,
.match-associate-filter-single:hover {
    background: var(--light-blue);
}

.match-associate-games-single:hover {
    background: var(--hover-blue);
}

.remove-date-icon:hover {
    color: var(--dark-yellow);
}

/* MEDIA QUERIES */
@media screen and (max-width: 768px) {
    .match-associate-filter-title,
    .match-associate-filter-single {
        font-size: 12px;
    }
    .match-associate-single-info {
        font-size: 12px;
        gap: 5px;
    }
    .upload-selected-match {
        height: fit-content;
        margin-top: 3px;
        gap: 3px;
    }
    .match-date-picker input[type="text"] {
        font-size: 12px;
    }
    .match-date-picker.has-date input[type="text"] {
        width: 75px;
    }
    .has-date .match-date-icon {
        font-size: 17px;
    }
    .remove-date-icon {
        font-size: 12px;
        margin: 0;
    }
    .match-associate-filter {
        height: 30px;
        width: 125px;
    }
}

@media screen and (max-width: 480px) {
    .match-associate-filter-cont {
        gap: 10px;
    }
    .match-associate-games-single {
        flex-direction: column;
        align-items: flex-start;
        height: 45px;
        border-radius: 3px;
    }
    .match-date-picker {
        height: 24px;
    }
    .match-associate-filter-cont {
        margin-bottom: 5px;
    }
    .match-associate-filter-title,
    .match-associate-filter-single {
        font-size: 10px;
    }
    .match-associate-date {
        width: 60px;
        font-size: 9px;
        padding: 1px;
        border-top-left-radius: 3px;
    }
    .match-associate-single-info {
        font-size: 10px;
        width: 100%;
        gap: 3px;
        margin-top: 3px;
        margin-bottom: 7px;
    }
    .upload-selected-match {
        font-size: 10px;
        gap: 3px
    }
    .match-associate-games-single img,
    .upload-selected-match img {
        height: 18px;
    }
    .match-associate-games-single.no-match {
        font-size: 12px;
        height: 32px;
    }
    .upload-selected-match-date {
        margin-left: 0;
    }
    .date-picker-icon,
    .match-date-icon {
        font-size: 17px;
    }
    .match-date-picker input[type="text"] {
        font-size: 10px;
    }
    .match-date-picker.has-date input[type="text"] {
        width: 60px;
    }
    .match-associate-filter {
        height: 25px;
        width: 125px;
    }
}