.category-params-modal-cont {
    margin: 0 auto;
    width: 400px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    padding-top: 15px;
}

.category-params-input-cont {
    width: 100%;
    display: flex;
    flex-direction: column;
    margin-bottom: 10px;
    gap: 20px;
    flex-grow: 1;
    overflow-y: scroll;
}

.category-param-details {
    display: flex;
    gap: 15px;
}

.category-param-single {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 3px;
    /* border: 1px solid red; */
}

.category-param-title {
    font-style: italic;
    color: rgba(255, 255, 255, 0.7);
}

.param-player-name {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.param-player-name.interview {
    margin-top: 3px;
    margin-bottom: 12px;
}

.remove-player-name {
    display: flex;
    align-items: center;
    gap: 3px;
    margin-right: 15px;
    color: rgba(255, 255, 255, 0.7)!important;
    cursor: pointer;
}

.param-add-player {
    color: rgba(255, 255, 255, 0.5);
}

.add-coach-cont input {
    margin-bottom: 10px;
}

.search-player-cont {
    display: flex;
    align-items: center;
    padding: 7px 0;
    padding-left: 7px;
    gap: 7px;
}

.search-player-result-list {
    background-color: black;
    margin-top: 5px;
    padding-top: 5px;
    border-top: 1px solid var(--dark-yellow);
}

.no-category-param {
    font-size: 16px;
}

.add-player-coach {
    font-style: italic;
    color: rgba(255, 255, 255, 0.7);
}

/* HOVER */
.remove-player-name:hover {
    color: var(--button-red)!important;
}