.metadata-payment {
    display: flex;
}

.payment-plan-cont {
    display: flex;
    flex-direction: column;
    align-items: center;
    border: 1px solid rgba(255, 255, 255, 0.3);
    border-radius: 3px;
}

.price-merchant {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    gap: 3px;
    padding: 8px 0;
    font-size: 14px;
    border-bottom: 1px solid rgba(255, 255, 255, 0.3);
}

.merchant-club-logo {
    height: 20px;
}

.payment-price-name {
    margin: 0 10px;
    margin-top: 10px;
    font-size: 17px;
    font-weight: 700;
    color: var(--light-blue);
}

.payment-period-ended {
    margin-top: 7px;
    color: var(--dark-yellow);
    font-weight: 500;
}

.price-period-cont {
    display: flex;
    flex-direction: column;
    gap: 5px;
    padding: 10px;
}

.price-period-info {
    display: flex;
    align-items: center;
    gap: 5px;
}

.price-period-title {
    width: 95px;
    text-align: right;
    color: rgba(255, 255, 255, 0.6);
}

.price-period-days {
    font-weight: 600;
}

/* MEDIA QUERY */
@media screen and (max-width: 480px) {
    .price-merchant ,
    .price-period-title,
    .price-period-days,
    .price-period-until {
        font-size: 12px;
    }
    .price-merchant {
        padding: 5px 0;
    }
    .payment-price-name {
        margin-top: 5px;
        font-size: 14px;
    }
    .price-period-cont {
        padding: 5px 8px;
    }
    .payment-period-ended {
        margin-top: 3px;
    }
}