.generate-highlights-btn {
    width: 100%;
    margin-top: 5px;
    margin-bottom: 10px;
    font-size: 15px;
}

.generate-highlights-cont {
    margin: 0 auto;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 10px 5px;
}

.generate-highlights-title {
    font-size: 17px;
    text-align: center;
    margin-bottom: 25px;
}

.generate-highlights-form {
    display: flex;
    flex-direction: column;
    gap: 5px;
}

.custom-highlights-options-cont {
    padding: 3px 0;
}

.custom-highlights-title {
    display: flex;
    align-items: center;
    height: 52px;
    gap: 7px;
}

.maximum-duration-cont {
    height: 75%;
}

.maximum-duration {
    display: flex;
    align-items: center;
    gap: 10px;
    margin-bottom: 7px;
}

.maximum-duration input[type="number"] {
    width: 55px;
    height: 40px;
    min-height: unset;
    font-size: 14px;
    padding-left: 10px;
}

.maximum-duration input[type="number"]::-webkit-outer-spin-button,
.maximum-duration input[type="number"]::-webkit-inner-spin-button {
  -webkit-appearance: inner-spin-button;
  opacity: 1;
}

.duration-info {
    display: flex;
    align-items: center;
    margin-top: 10px;
    gap: 5px;
}

.highlights-event-cont {
    display: flex;
    flex-direction: column;
    gap: 7px;
}

.highlights-event-title {
    margin-bottom: 3px;
    color: rgba(255, 255, 255, 0.8);
}

.highlights-event-single {
    width: fit-content;
    display: flex;
    align-items: center;
    gap: 7px;
    cursor: pointer;
    margin-left: 3px;
    font-size: 14px;
}

.highlights-event-single.active {
    color: var(--dark-yellow);
}

.custom-highlights-cont {
    width: 100%;
}

.empty-event-warning {
    display: flex;
    align-items: center;
    height: 25px;
    font-size: 13px;
}

.to-existing-video-edit {
    color: var(--light-blue);
    cursor: pointer;
    text-decoration: underline;
    font-size: 14px;
}

/* HOVER */
.highlights-event-single:hover,
.to-existing-video-edit:hover {
    color: var(--dark-yellow);
}